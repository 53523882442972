import { FunctionComponent, useEffect } from "react"
import useIsMobile from "../lib/mobile";

interface ModalProps {
    /** is modal opened or not ? */
    opened: boolean
    title: string
}

const Modal: FunctionComponent<ModalProps> = ({ opened, title, children }) => {
    const isMobile = useIsMobile()

    useEffect(() => {
        // disabled scroll when modal is opened
        if (opened) {
            if (isMobile){
                document.body.style.position = 'fixed'
                document.body.style.top = ''
            }
            else{
                document.body.style.top = `-${window.scrollY}px`
                document.body.style.position = ''
            }
        }
        else {
            document.body.style.position = ''
            document.body.style.top = ''
        }
    }, [opened, isMobile])
    return (
        <>
            {opened &&
                <div className="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded shadow-lg w-10/12 md:w-1/3">
                        <div className="border-b px-4 py-2 flex justify-between items-center">
                            <h3 className="font-semibold text-lg">{title}</h3>
                            {/* <button className="text-black close-modal">&cross;</button> */}
                        </div>
                        <div className="p-3">
                            {children}
                        </div>
                        {/* <div className="flex justify-end items-center w-100 border-t p-3">
                            <button className="bg-red-600 hover:bg-red-700 px-3 py-1 rounded text-white mr-1 close-modal">Cancel</button>
                            <button className="bg-blue-600 hover:bg-blue-700 px-3 py-1 rounded text-white">Oke</button>
                        </div> */}
                    </div>
                </div>
            }
        </>
    )
}
export default Modal




