import { FunctionComponent } from "react"
import Link from 'next/link'

const Footer: FunctionComponent = () => {

    return (
        <footer className="footer">
            <Link href="/legal" prefetch={false}>Legal information</Link>
        </footer>
    )
}
export default Footer
