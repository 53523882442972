import Head from "next/head"
import { FunctionComponent, ReactElement, useEffect, useState } from "react"
import Script from "next/script"
import { GPT_URL, GTAG_KEY, GTM_KEY, PREBID_URL } from "../config/partners"
import { DidomiSDK } from "@didomi/react"
// import { useOption } from "../lib/hooks"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { useStore } from "../store"
import Modal from "./modal"
import Footer from "./footer"

import { observer } from "mobx-react-lite"
import { isMobile } from "../lib/mobile"
import { getSourceFromUrl } from "../lib/cms"

interface LayoutProps {
  children: ReactElement
}

const Layout: FunctionComponent<LayoutProps> = observer(({ children }) => {
  const [hasAdBlocker, setHasAdBlocker] = useState(false)
  const { adStore } = useStore()
  // const cmp = useOption("cmp")
  // const ad = useOption("ad")

  useEffect(() => {
    // set google targeting keys
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
    window.googletag.cmd.push(() => {
      window.googletag.pubads().setTargeting('source', getSourceFromUrl())
    })
  }, [])

  // console.log("ad", ad)
  const router = useRouter()

  // route change
  useEffect(() => {
    window.isMobile = isMobile()
    const handleRouteChange = (url: string) => {
      // Without this test, page is considered reloaded if parameters are present on the page
      // cf https://github.com/vercel/next.js/issues/11639
      // This is not ideal at all. It works only because we don't have page with parameters '?xxx=yyy'
      if (url.includes("?")) return
      // cleanup ads
      // adStore.removeSlots()
      // start a new page, no need to include outbrain yet (it will be reloaded by lazy loading later)
      adStore.disableOutbrain()
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [])

  // handle ad rendered event
  useEffect(() => {
    window.googletag.cmd.push(() => {
      // remove placeholder when an ad is rendered
      // handle this globally here instead of a state to avoid unnecessary rerenders
      window.googletag.pubads().addEventListener("slotRenderEnded", function (event: any) {
        const slot = document.querySelector(`#${event.slot.getSlotId().getDomId()}`)?.parentElement
        if (slot) slot.classList.remove("ad-placeholder")
      })
    })
  }, [])

  // test if reworldAd is present, if not then there is an adblocker (or script is unavailable)
  // disable adblocker
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     setTimeout(() => {
  //       if (!window.reworldAd.refresh) setHasAdBlocker(true)
  //     }, 1000)
  //   }
  // }, [])

  // there is a bug in dev environnement where "beforeInteractive" scripts are not loaded
  // TODO : investigate
  const loadStrategy = process.env.NODE_ENV === 'development' ? "afterInteractive" : "beforeInteractive"

  return (
    <>
      <>
        <DidomiSDK
          apiKey="745a135f-908d-4bfb-beca-56b93e25dc45"
          iabVersion={2}
          gdprAppliesGlobally={true}
          onReady={(didomi) => console.log("Didomi SDK is loaded and ready", didomi)}
          onConsentChanged={(cwtToken) => console.log("Didomi A consent has been given/withdrawn", cwtToken)}
          onNoticeShown={() => console.log("Didomi Notice Shown")}
          onNoticeHidden={() => console.log("Didomi Notice Hidden")}
        />
      </>

      <Script id="GPT" src={GPT_URL} async strategy={loadStrategy} />
      <Script id="prebid" src={PREBID_URL} async strategy={loadStrategy} />

      {/* make sure it's executed in client and not on server */}
      <Script id="gpt_init">
        {`window.googletag = window.googletag || {};
          window.googletag.cmd = window.googletag.cmd || [];

          window.reworldAd = window.reworldAd || {};
          window.reworldAd.que = window.reworldAd.que || [];

          window.googletag.cmd.push(() => {
            window.googletag.pubads().disableInitialLoad();
            window.googletag.pubads().enableSingleRequest();
            window.googletag.enableServices();
          })`}
      </Script>

      {/* Pubstack */}
      <Script async src="https://boot.pbstck.com/v1/tag/f9038bd3-bfe5-4c7f-b044-dc0a5b58f975" strategy="afterInteractive" />

      {/* Google tag manager */}
      <Script id="GTM" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_KEY}');`}
      </Script>

      {/* Avantis video */}
      {/* <Script async src="https://cdn.avantisvideo.com/avm/js/video-loader.js?id=37a16c48-87e3-4341-b9ab-c7c9469fbf98&tagId=87" id="avantisJS" strategy="afterInteractive"></Script> */}

      {/* Outbrain pixel code */}
      <Script id="outbrain" strategy="lazyOnload">
        {`
        !function(_window, _document) {
            var OB_ADV_ID =['006b3bc078a48f8e8b930facc23018a681', '0096bb3f4f763d69a5fb4e4c7f6d049fa9', '00a9143c8e1a5a6f1e485de389dcf20b59', '00ecc6050d8e6f90d02f74285a89ebf1f3', '00da1fce643740283fabc5cd44a68f1085', '0086cfd47a3c8c80c835be05f89d510ea5', '00f3213efedf7a9b6c0d6661c17f60342e', '00196295317a2ac7fe940a450f0d8d8ac4', '00cb559e63474fe8c96cafcb5c41549d5a', '00ec3f6529ed9620019fd00a59171fce14', '00aca1bfdbdfd2f3f620eb8257e508d95b', '003b9ea120cb792ccc408ace33859c5479', '006587f5c759245549752d832a7919c1cf', '00d2105cbd4a8fd47320cb5a6269d1c167', '00e3cc511d7bede9b05ecb373e7c2ddebb', '00fc79bdd01c3741794c04f12322a9cb0f', '00d6236ef9433c39f2575379f5191b7ec0', '007ee8d7e68dbd66db8dbb2b21b04c6714'];
            if (_window.obApi) {
              var toArray = function(object) {
                return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
              };
              _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
              return;
            }
            var api = _window.obApi = function() {
              api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
            };
            api.version = '1.1';
            api.loaded = true;
            api.marketerId = OB_ADV_ID;
            api.queue = [];
            var tag = _document.createElement('script');
            tag.async = true;
            tag.src = '//amplify.outbrain.com/cp/obtp.js';
            tag.type = 'text/javascript';
            var script = _document.getElementsByTagName('script')[0];
            script.parentNode.insertBefore(tag, script);
          }(window, document);

          obApi('track', 'PAGE_VIEW');`}
      </Script>

      {/* Taboola pixel code */}
      <Script id="taboola" strategy="lazyOnload">
        {`window._tfa = window._tfa || [];
          window._tfa.push({notify: 'event', name: 'page_view', id: 1427986});
          !function (t, f, a, x) {
                if (!document.getElementById(x)) {
                    t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
                }
          }(document.createElement('script'),
          document.getElementsByTagName('script')[0],
          '//cdn.taboola.com/libtrc/unip/1427986/tfa.js',
          'tb_tfa_script');`}
      </Script>



      <Head>
        <title>BE</title>
        <meta name="description" content="BE" />
        <link rel="preconnect" href="https://id5-sync.com" />
        <link rel="preconnect" href="https://www.googletagservices.com" />
        <link rel="preconnect" href="https://prebid.reworldmediafactory.com" />
      </Head>

      <header className="header">
        <Link href="/" passHref>
          <a title="Home" className="logo">
            <Image src="/logo.svg" width="500" height="120" title="Home" alt="Logo BE" />
          </a>
        </Link>
      </header>

      <div className="container mx-auto max-w-8xl p-4 z-10">
        {hasAdBlocker && (
          <main>
            <Modal title={"Ad blocker detected"} opened={true}>
              Please, <strong>turn off</strong> your ad blocker in order to continue browsing.
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-4 px-4 rounded inline-flex items-center w-full justify-center my-4 hover:shadow-xl shadow-md disabled:opacity-50 transition"
                onClick={() => window.location.reload()}
              >
                Refresh page
              </button>
            </Modal>
          </main>
        )}
        <main>
          {children}

          {adStore.isEnabledOutbrain && (
            <>
              <div
                className="OUTBRAIN"
                data-src={window.location.href}
                data-widget-id="AR_1"
              // data-widget-id={adStore.isMobile ? "AR_1" : "SB_1"}
              ></div>
              <Script
                type="text/javascript"
                src="//widgets.outbrain.com/outbrain.js?i=be797886"
                strategy="lazyOnload"
              />
            </>
          )}
        </main>
      </div>
      <Footer />
    </>
  )
})
export default Layout
