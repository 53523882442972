import { PostExcerpt } from "./api"

const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || "http://localhost:3000/"
const urlPattern = /<a\s+(?:[^>]*?\s+)?href=[\n]?(["'])(.*?)\1/g

/** convert urls inside cms editor to proper next links
 * exemple : https://backend.thefinefinance.com/people/these-celebrity-couples-who-dont-live-together-5612.html become ${SITE_URL}/the-most-expensive-hollywood-weddings
 */
export const convertLinks = (text: string) => {
    let match
    let newText = text
    while (null != (match = urlPattern.exec(text))) {

        let oldUrl = new URL(match[2])
        // remove -xxxx.html at the end of the url
        const cutoffEnd = oldUrl.pathname.lastIndexOf("-")
        const cutoffStart = oldUrl.pathname.indexOf('/')
        let newPath = oldUrl.pathname.slice(cutoffStart + 1, cutoffEnd)
        if (newPath.indexOf('/') !== -1)
            newPath = newPath.slice(newPath.indexOf('/') + 1)
        let newUrl = `${SITE_URL}${newPath}`
        newText = newText.replace(match[2], newUrl)
    }

    return newText

}



const SOURCE_MAPPING: {[key:string]: string} = {
    'taboola_mobile': 'paid_taboola',
    'taboola_desktop': 'paid_taboola',
    'taboola_tablet': 'paid_taboola',
    'outbrain_mobile': 'paid_outbrain',
    'outbrain_desktop': 'paid_outbrain',
    'outbrain_tablet': 'paid_outbrain',
    'revc': 'paid_revcontent',
    'mobile_facebook': 'paid_facebook',
    'desktop_facebook': 'paid_facebook',
    'tablet_facebook': 'paid_facebook',
    'tiktok': 'paid_tiktok',
    'snap': 'paid_snap',
    'pausecafein': 'paid_pausecafein',
    'twitter': 'paid_twitter',
    'pt_mf': 'paid_powerspace',
    'ct_mf': 'paid_powerspace',
    'pa_mf': 'paid_powerspace',
    'ividence': 'paid_ividence',
    'supremedia': 'paid_supremedia',
    'sprmdfp': 'paid_supremedia',
    'google': 'paid_google',
    'media10': 'paid_media10',
    'cpttraff': 'paid_captain',
    'captaintraffic': 'paid_captain',
}

export const getSourceFromUrl = ():string => {
    const params = new URL(window.location.href).searchParams
    const utm_source = params.get('utm_source')?.toLowerCase()
    if (!utm_source)
        return 'organique'
    if(utm_source in SOURCE_MAPPING)
        return SOURCE_MAPPING[utm_source]
    return utm_source
}

export const getCategoryFromPost = (post: PostExcerpt): string => {
    if(!post.categories.nodes.length)
        return "post"
    let category = post.categories.nodes[0].slug

    for(let cat of post.categories.nodes){
        if(!["diaporama-accueil", "diapo_monetisation"].includes(cat.slug)){
            category = cat.slug
            break
        }
    }
    return category
}