import type { GetStaticProps, NextPage } from "next"
import Head from "next/head"
import Image from "next/image"
import Link from "next/link"
import { getAllPosts, Posts } from "../lib/api"
import Layout from "../components/layout"
import { getCategoryFromPost } from "../lib/cms"
const IMAGE_BASE_URL = process.env.NEXT_PUBLIC_WP_IMAGE_BASE_URL || "https://backend.thefinefinance.com/wp-content/uploads/"

type Props = {
  posts: Posts
}

const Home: NextPage<Props> = ({ posts }) => {

  return (
    <Layout>
      <>
        <Head>
          <title>The Fine Finance</title>
        </Head>

        <main>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            {posts.edges.map(({ node }, index) => {
              const image = node.featuredImage.node.mediaDetails.file
              const category = getCategoryFromPost(node)
              return (
                <section
                  key={node.id}
                  className="shadow-lg group container  rounded-md bg-white  max-w-sm flex justify-center items-center mx-auto content-div transform hover:scale-105 transition"
                  style={{ maxWidth: '300px' }}>
                  <Link href={`/${category}/${node.slug}`} passHref>
                    <a title="Read more" className="no-underline">
                      {image &&
                        <div className="max-h-[150px] overflow-hidden">
                          <figure>
                            <Image
                              src={`${IMAGE_BASE_URL}/${image}`}
                              alt={node.featuredImage.node.altText}
                              title={node.featuredImage.node.title}
                              width={node.featuredImage.node.mediaDetails.width}
                              height={node.featuredImage.node.mediaDetails.height}
                              objectFit="cover"
                              className="w-full rounded-t-md image-cover max-h-52"
                              priority={index <= 3} />
                          </figure>
                        </div>
                      }
                      <h2 className="block text-lg text-gray-800 font-bold tracking-wide p-2">{node.title}</h2>
                    </a>
                  </Link>
                </section>

              )
            }
            )}
          </div>
        </main>
      </>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps<Props> = async (context) => {
  const posts = await getAllPosts(false)

  return {
    props: {
      posts
    },
    revalidate: 3600
  }
}

export default Home
